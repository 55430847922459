<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="formularioValido"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveTipoSolicitud"
        >
          <v-row>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <v-text-field
                v-model.trim="tipoSolicitudNom"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(tipoSolicitudNom, 50),
                    rules.requiredTrim(tipoSolicitudNom)
                  ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <v-select
                v-model="tipoTitularSelected"
                :items="tiposTitulares"
                :rules="rules.required"
                item-text="value"
                item-value="id"
                label="Tipo titular"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex py-0 justify-content-center">
              <v-switch v-model="modificaConvenio" id="switch1"></v-switch>
              <label for="switch1"
                >Permitir modificar el convenio al editar la solicitud de
                afiliación</label
              >
              <v-spacer></v-spacer>
              <v-switch v-model="datosAdicionales" id="switch2"></v-switch>
              <label for="switch2"
                >Habilitar datos adicionales en la carga web para Traspasos de
                O.S.</label
              >
            </v-col>
            <v-col cols="12" class="d-flex py-0 justify-content-center">
              <v-switch
                v-model="sinEntregaPrevia"
                id="switch3"
                @change="changeValueNumeradorAutomatico()"
              ></v-switch>
              <label for="switch3"
                >Permitir cargar sin entrega previa al vendedor</label
              >
              <v-spacer></v-spacer>
              <v-switch
                v-model="numeradorAutomatico"
                id="switch4"
                :disabled="!sinEntregaPrevia"
                @change="numeracionInicial = null"
              ></v-switch>
              <label for="switch4"
                >Generar número de solicitud automáticamente</label
              >
            </v-col>
            <v-col cols="12" class="d-flex py-0 justify-content-center">
              <v-switch
                v-model="unicoPlanXGrupoFamiliar"
                :disabled="permiteCambiarConvenio"
                id="switch5"
              ></v-switch>
              <label for="switch5"
                >Todos los integrantes del grupo deben tener el mismo
                plan</label
              >
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <v-text-field
                v-model.trim="numeracionInicial"
                label="Numeración inicial"
                dense
                v-mask="'#########'"
                :disabled="!numeradorAutomatico"
                :rules="
                  numeradorAutomatico
                    ? rules.required.concat(rules.positiveNumberHigherThanCero)
                    : []
                "
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <v-text-field
                v-model.trim="cantMesesAporteEsperado"
                label="Cantidad de meses de aporte esperado"
                dense
                v-mask="'##'"
                :rules="
                  cantMesesAporteEsperado != null ? rules.positiveNumber : []
                "
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          :loading="loadingBtn"
          type="submit"
          :disabled="!formularioValido || loadingBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";

export default {
  name: "EditTiposSolicitud",
  props: {
    tipoSolicitud: {
      type: Object,
      required: false
    }
  },
  directives: { mask },
  data() {
    return {
      formularioValido: false,
      formEditTitle: "Editar tipo de solicitud",
      rules: rules,
      modificaConvenio: false,
      datosAdicionales: false,
      sinEntregaPrevia: false,
      numeradorAutomatico: false,
      tiposTitulares: [],
      tipoTitularSelected: null,
      tipoSolicitudNom: null,
      numeracionInicial: null,
      cantMesesAporteEsperado: null,
      unicoPlanXGrupoFamiliar: false,
      loadingBtn: false,
      permiteCambiarConvenio: false
    };
  },
  created() {
    this.setSelects();
    this.setParametrosGrales();
    if (this.tipoSolicitud != null) {
      this.setTipoSolicitud();
    } else {
      this.newTipoSolicitud();
    }
  },
  methods: {
    ...mapActions({
      getTiposTitulares: "configAfiliaciones/getTiposTitulares",
      postTipoSolicitud: "configAfiliaciones/postTipoSolicitud",
      getParametrosGralesBenef: "afiliaciones/getParametrosGralesBenef",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const tiposTitulares = await this.getTiposTitulares();
      this.tiposTitulares = tiposTitulares;
    },
    setTipoSolicitud() {
      this.tipoSolicitudNom = this.tipoSolicitud.solicitudTipoNom;
      this.modificaConvenio = this.tipoSolicitud.modificaConvenio;
      this.datosAdicionales = this.tipoSolicitud.esTraspaso;
      this.sinEntregaPrevia = this.tipoSolicitud.cargaSinEntregaPrevia;
      this.numeradorAutomatico = this.tipoSolicitud.generaNumeracionAutomatica;
      this.tipoTitularSelected = this.tipoSolicitud.tipoTitularId;
      this.numeracionInicial = this.tipoSolicitud.numeracionInicial;
      this.cantMesesAporteEsperado = this.tipoSolicitud.mesesVigenciaAporteEsperado;
      this.unicoPlanXGrupoFamiliar = this.tipoSolicitud.unicoPlanXGrupoFamiliar;
    },
    newTipoSolicitud() {
      this.formEditTitle = "Nuevo tipo de solicitud";
    },
    async saveTipoSolicitud() {
      this.loadingBtn = true;
      const data = {
        solicitudTipoId:
          this.tipoSolicitud != null
            ? this.tipoSolicitud.solicitudTipoId
            : null,
        solicitudTipoNom: this.tipoSolicitudNom,
        tipoTitularId: this.tipoTitularSelected,
        modificaConvenio: this.modificaConvenio,
        esTraspaso: this.datosAdicionales,
        cargaSinEntregaPrevia: this.sinEntregaPrevia,
        generaNumeracionAutomatica: this.numeradorAutomatico,
        numeracionInicial: this.numeracionInicial,
        mesesVigenciaAporteEsperado: this.cantMesesAporteEsperado,
        unicoPlanXGrupoFamiliar: this.unicoPlanXGrupoFamiliar
      };
      const res = await this.postTipoSolicitud(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
      this.loadingBtn = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    changeValueNumeradorAutomatico() {
      if (!this.sinEntregaPrevia) this.numeradorAutomatico = false;
    },
    async setParametrosGrales() {
      const response = await this.getParametrosGralesBenef();
      this.permiteCambiarConvenio = response.permiteCambiarConvIntegrante;
    }
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
</style>
