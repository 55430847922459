<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
        <span class="pl-1 primary--text">{{ estSolNom }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="validForm"
          ref="form-grupos-usuarios"
          form="form-grupos-usuarios"
          id="form-grupos-usuarios"
          @submit.prevent="saveGruposUsuario"
        >
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="grupos"
                v-model="grupoUsuarioSelected"
                :search="searchGruposUsuario"
                item-key="id"
                show-select
                hide-default-footer
                :items-per-page="99"
                dense
                class="elevation-1"
                @toggle-select-all="selectAllGruposUsuariosToggle"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="searchGruposUsuario"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template
                  v-slot:[`item.data-table-select`]="{
                    isSelected,
                    select
                  }"
                >
                  <v-simple-checkbox
                    :disabled="!canConfigGroups"
                    :value="isSelected"
                    color="primary"
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          v-if="canConfigGroups"
          :loading="loadingBtn"
          :disabled="!validForm || loadingBtn"
          form="form-grupos-usuarios"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "GruposUsuarioEstados",
  props: {
    estSolId: {
      type: Number,
      required: true
    },
    estSolNom: {
      type: String,
      required: true
    },
    canConfigGroups: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      validForm: false,
      searchIcon: enums.icons.SEARCH,
      formEditTitle: `Grupos de usuario del estado:`,
      rules: rules,
      searchGruposUsuario: "",
      headers: [
        {
          text: "Grupo de usuario",
          align: "start",
          value: "value",
          sortable: false
        }
      ],
      grupos: [],
      grupoUsuarioSelected: [],
      loadingBtn: false
    };
  },
  created() {
    this.setGruposUsuario();
  },
  methods: {
    ...mapActions({
      getGruposDeUsuarios: "configAfiliaciones/getGruposDeUsuarios",
      getGruposUsuarioById: "configAfiliaciones/getGruposUsuarioById",
      saveGruposUsuarioEstado: "configAfiliaciones/saveGruposUsuarioEstado",
      setAlert: "user/setAlert"
    }),
    async setGruposUsuario() {
      const grupos = await this.getGruposDeUsuarios();
      this.grupos = grupos;
      const gruposUsuarioEstado = await this.getGruposUsuarioById(
        this.estSolId
      );
      grupos.forEach(x => {
        gruposUsuarioEstado.forEach(y => {
          if (y.grupoId === x.id) this.grupoUsuarioSelected.push(x);
        });
      });
    },
    selectAllGruposUsuariosToggle() {
      if (!this.canConfigGroups) {
        this.grupoUsuarioSelected = [];
        this.setGruposUsuario();
        this.setAlert({
          type: "warning",
          message: "No se puede seleccionar ningún grupo."
        });
        return;
      }
      if (this.grupoUsuarioSelected.length != this.grupos.length) {
        this.grupoUsuarioSelected = [];
        this.grupos.forEach(item => {
          this.grupoUsuarioSelected.push(item);
        });
        this.setAlert({
          type: "info",
          message: "Todos los grupos de usuario han sido seleccionados."
        });
      } else {
        this.grupoUsuarioSelected = [];
        this.setAlert({
          type: "info",
          message: "Todos los grupos de usuario han sido deseleccionados."
        });
      }
    },
    async saveGruposUsuario() {
      this.loadingBtn = true;
      let userGroups = [];
      if (this.grupoUsuarioSelected.length > 0)
        this.grupoUsuarioSelected.forEach(x => {
          userGroups.push({ estSolId: this.estSolId, grupoId: x.id });
        });
      else userGroups.push({ estSolId: this.estSolId, grupoId: null });
      const data = {
        userGroupData: userGroups
      };

      const res = await this.saveGruposUsuarioEstado(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
      this.loadingBtn = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped></style>
