<template>
  <v-card>
    <v-container>
      <v-card-title class="py-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card elevation="0">
              <v-data-table
                :headers="headersEstados"
                :items="estadosOrigen"
                item-key="estSolId"
                :search="searchEstados"
                dense
                class="elevation-1"
              >
                <template v-slot:[`item.estSolDevueltoCobrador`]="{ item }">
                  <v-icon
                    v-if="item.estSolDevueltoCobrador"
                    small
                    color="primary"
                  >
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:[`item.estSolFacturado`]="{ item }">
                  <v-icon v-if="item.estSolFacturado" small color="primary">
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:[`item.estSolInicial`]="{ item }">
                  <v-icon v-if="item.estSolInicial" small color="primary">
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:[`item.estSolAlta`]="{ item }">
                  <v-icon v-if="item.estSolAlta" small color="primary">
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:[`item.estSolApto`]="{ item }">
                  <v-icon v-if="item.estSolApto" small color="primary">
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:[`item.estSolAltaProvisoria`]="{ item }">
                  <v-icon
                    v-if="item.estSolAltaProvisoria"
                    small
                    color="primary"
                  >
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:[`item.estSolBajaRechazo`]="{ item }">
                  <v-icon v-if="item.estSolBajaRechazo" small color="primary">
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                          v-model="searchEstados"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3" align="end" v-if="canCreate">
                        <v-btn
                          color="primary"
                          class="to-right"
                          outlined
                          @click="toggleModalHeredarConfig()"
                        >
                          Heredar
                        </v-btn>
                      </v-col>
                      <v-col cols="1" align="end" v-if="canCreate">
                        <v-btn
                          color="primary"
                          class="to-right"
                          @click="toggleModalEditEstado()"
                        >
                          Nuevo
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="toggleModalDestinosEstado(item)"
                      >
                        {{ destinosIcon }}
                      </v-icon>
                    </template>
                    <span>Destinos</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="toggleModalGruposUsuario(item)"
                      >
                        {{ groupIcon }}
                      </v-icon>
                    </template>
                    <span>Grupos de usuario</span>
                  </v-tooltip>
                  <v-tooltip left v-if="canEdit">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="toggleModalEditEstado(item.estSolId)"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <span>Editar estado</span>
                  </v-tooltip>
                  <v-tooltip left v-if="canDelete">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="deleteEstado(item)"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar estado</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
    <v-dialog
      v-if="openModalEditEstado"
      v-model="openModalEditEstado"
      max-width="50%"
      @keydown.esc="closeAndReloadModalEditEstado()"
      persistent
    >
      <EditEstadoSolicitud
        :estSolId="estSolId"
        :tipoSolicitudId="tipoSolicitudId"
        @closeAndReload="closeAndReloadModalEditEstado"
      />
    </v-dialog>
    <v-dialog
      v-if="openModalGruposUsuario"
      v-model="openModalGruposUsuario"
      max-width="50%"
      @keydown.esc="closeAndReloadModalGruposUsuario()"
      persistent
    >
      <GruposUsuarioEstados
        :canConfigGroups="canConfigGroups"
        :estSolId="estSolId"
        :estSolNom="estSolNom"
        @closeAndReload="closeAndReloadModalGruposUsuario"
      />
    </v-dialog>
    <v-dialog
      v-if="openModalDestinosEstado"
      v-model="openModalDestinosEstado"
      max-width="60%"
      @keydown.esc="closeAndReloadModalDestinosEstado()"
      persistent
    >
      <DestinosEstadosSolicitud
        :canConfigDestinos="canConfigDestinos"
        :estadosDestino="estadosDestino"
        :tipoSolicitudId="tipoSolicitudId"
        :canCreate="canCreate"
        :estSolIdActual="estSolId"
        :estSolNom="estSolNom"
        @closeAndReload="closeAndReloadModalDestinosEstado"
      />
    </v-dialog>
    <v-dialog
      v-if="openModalHeredarConfig"
      v-model="openModalHeredarConfig"
      max-width="80%"
      @keydown.esc="closeAndReloadModalHeredarConfig()"
      persistent
    >
      <HeredarConfigTipoSolicitud
        :tipoSolicitudId="tipoSolicitudId"
        @closeAndReload="closeAndReloadModalHeredarConfig"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditEstadoSolicitud from "@/components/modules/afiliaciones/afiliados/configuracion/EditEstadoSolicitud.vue";
import GruposUsuarioEstados from "@/components/modules/afiliaciones/afiliados/configuracion/GruposUsuarioEstados.vue";
import DestinosEstadosSolicitud from "@/components/modules/afiliaciones/afiliados/configuracion/DestinosEstadosSolicitud.vue";
import HeredarConfigTipoSolicitud from "@/components/modules/afiliaciones/afiliados/configuracion/HeredarConfigTipoSolicitud.vue";

export default {
  name: "ConfigTipoSolicitud",
  props: {
    tipoSolicitudId: {
      type: Number,
      required: true
    },
    tipoSolicitudNom: {
      type: String,
      required: true
    }
  },
  components: {
    EditEstadoSolicitud,
    GruposUsuarioEstados,
    DestinosEstadosSolicitud,
    HeredarConfigTipoSolicitud,
    DeleteDialog
  },
  data() {
    return {
      formEditTitle: `${enums.titles.CONFIGURACION_TIPO_SOLICITUD}: ${this.tipoSolicitudNom}`,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      rules: rules,
      checkIcon: enums.icons.CHECK_OUTLINE,
      groupIcon: enums.icons.GROUP,
      destinosIcon: enums.icons.MAP_MARKER,
      titleDelete: "¿Desea eliminar estado de solicitud?",
      openModalGruposUsuario: false,
      searchEstados: "",
      searchIcon: enums.icons.SEARCH,
      headersEstados: [
        {
          text: "Estado de solicitud",
          align: "start",
          value: "estSolNom",
          sortable: false
        },
        {
          text: "Provisoria",
          align: "center",
          value: "estSolAltaProvisoria",
          sortable: false
        },
        {
          text: "Inicial",
          align: "center",
          value: "estSolInicial",
          sortable: false
        },
        {
          text: "Apto",
          align: "center",
          value: "estSolApto",
          sortable: false
        },
        {
          text: "Alta",
          align: "center",
          value: "estSolAlta",
          sortable: false
        },
        {
          text: "Facturado",
          align: "center",
          value: "estSolFacturado",
          sortable: false
        },
        {
          text: "Baja por rechazo",
          align: "center",
          value: "estSolBajaRechazo",
          sortable: false
        },
        {
          text: "Devuelto al cobrardor",
          align: "center",
          value: "estSolDevueltoCobrador",
          sortable: false
        },
        {
          text: "Acciones",
          value: "actions",
          align: "end",
          width: "8%",
          sortable: false
        }
      ],
      estadosOrigen: [],
      estSolId: null,
      estSolNom: null,
      canEdit: false,
      canDelete: false,
      canCreate: false,
      canConfigGroups: false,
      canConfigDestinos: false,
      openModalEditEstado: false,
      showDeleteModal: false,
      openModalDestinosEstado: false,
      estadosDestino: [],
      optionCode: "74860C50-3E29-4280-8729-F379BB1727BA",
      openModalHeredarConfig: false
    };
  },
  created() {
    this.setPermisos();
    this.setEstadosByTipoSolicitud();
  },
  methods: {
    ...mapActions({
      getPermissionsEstadosSolicitudes:
        "configAfiliaciones/getPermissionsEstadosSolicitudes",
      getEstadosSolicitudByTipoSolicitud:
        "configAfiliaciones/getEstadosSolicitudByTipoSolicitud",
      deleteEstadosDeSolicitudes:
        "configAfiliaciones/deleteEstadosDeSolicitudes",
      setAlert: "user/setAlert"
    }),
    async setPermisos() {
      const permisos = await this.getPermissionsEstadosSolicitudes({
        optionCode: this.optionCode
      });
      this.canCreate = permisos.canCreate;
      this.canEdit = permisos.canEdit;
      this.canDelete = permisos.canDelete;
      this.canConfigGroups = permisos.canConfigGroups;
      this.canConfigDestinos = permisos.canConfigDestinos;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    async setEstadosByTipoSolicitud() {
      const response = await this.getEstadosSolicitudByTipoSolicitud(
        this.tipoSolicitudId
      );
      this.estadosOrigen = response;
    },
    toggleModalDestinosEstado(item) {
      this.estadosDestino = this.estadosOrigen.filter(
        x => x.estSolId != item.estSolId
      );
      this.estSolId = item.estSolId;
      this.estSolNom = item.estSolNom;
      this.openModalDestinosEstado = !this.openModalDestinosEstado;
    },
    toggleModalGruposUsuario(item) {
      this.estSolId = item.estSolId;
      this.estSolNom = item.estSolNom;
      this.openModalGruposUsuario = !this.openModalGruposUsuario;
    },
    toggleModalEditEstado(id) {
      this.estSolId = id;
      this.openModalEditEstado = !this.openModalEditEstado;
    },
    toggleModalHeredarConfig() {
      if (this.estadosOrigen.length > 0) {
        this.setAlert({
          type: "warning",
          message:
            "Debe eliminar los estados de solicitud cargados para heredar una configuración."
        });
        return;
      }
      this.openModalHeredarConfig = !this.openModalHeredarConfig;
    },
    deleteEstado(item) {
      this.idToDelete = item.estSolId;
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      const response = await this.deleteEstadosDeSolicitudes(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setEstadosByTipoSolicitud();
      }
    },
    closeAndReloadModalEditEstado() {
      this.openModalEditEstado = false;
      this.setEstadosByTipoSolicitud();
    },
    closeAndReloadModalGruposUsuario() {
      this.openModalGruposUsuario = false;
      this.setEstadosByTipoSolicitud();
    },
    closeAndReloadModalDestinosEstado() {
      this.openModalDestinosEstado = false;
      this.setEstadosByTipoSolicitud();
    },
    closeAndReloadModalHeredarConfig() {
      this.openModalHeredarConfig = false;
      this.setEstadosByTipoSolicitud();
    }
  }
};
</script>

<style scoped>
.preview-row {
  height: 150px;
  overflow-y: scroll;
  border: 1px solid #e6e6e6;
  margin: 0 auto;
  width: 75%;
}
.preview-container {
  margin-bottom: 2rem 0;
  padding: 0;
  width: 100%;
}

::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
