<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValido"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit"
        >
          <v-row>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <v-text-field
                v-model.trim="estadoNom"
                label="Nombre estado de solicitud"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(estadoNom, 50),
                    rules.requiredTrim(estadoNom)
                  ])
                "
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <v-select
                v-model="tipoSolicitudSelected"
                disabled
                :items="tiposSolicitudes"
                item-text="value"
                item-value="id"
                label="Tipo de solicitud"
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex py-0 justify-content-center">
              <v-switch
                v-model="cargaInicial"
                id="switch1"
                :disabled="bajaPorRechazo || facturado"
              ></v-switch>
              <label for="switch1">Estado de carga inicial</label>
              <v-spacer></v-spacer>
              <v-switch
                v-model="altaConCobertura"
                id="switch2"
                :disabled="bajaPorRechazo || aptoSinCobertura"
              ></v-switch>
              <label for="switch2">Es alta, pasa a tener cobertura</label>
            </v-col>
            <v-col cols="12" class="d-flex py-0 justify-content-center">
              <v-switch
                v-model="aptoSinCobertura"
                id="switch3"
                :disabled="bajaPorRechazo || altaConCobertura"
              ></v-switch>
              <label for="switch3"
                >Apto, se puede facturar pero no tiene cobertura</label
              >
              <v-spacer></v-spacer>
              <v-switch v-model="devueltoAlCobrador" id="switch4"></v-switch>
              <label for="switch4">Devuelto al cobrador</label>
            </v-col>
            <v-col cols="12" class="d-flex py-0 justify-content-center">
              <v-switch
                v-model="altaProvisoria"
                id="switch5"
                :disabled="bajaPorRechazo"
              ></v-switch>
              <label for="switch5">Alta provisoria</label>
              <v-spacer></v-spacer>
              <v-switch
                v-model="bajaPorRechazo"
                id="switch6"
                :disabled="
                  altaConCobertura ||
                    aptoSinCobertura ||
                    altaProvisoria ||
                    cargaInicial
                "
              ></v-switch>
              <label for="switch6">Baja por rechazo</label>
              <v-spacer></v-spacer>
              <v-switch
                v-model="facturado"
                id="switch7"
                :disabled="cargaInicial"
              ></v-switch>
              <label for="switch7">Facturado</label>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValido || loadingBtn"
          :loading="loadingBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";

export default {
  name: "EditEstadoSolicitud",
  props: {
    estSolId: {
      type: Number,
      required: false
    },
    tipoSolicitudId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      isFormValido: false,
      formEditTitle: "Editar estado de solicitud",
      rules: rules,
      cargaInicial: false,
      altaConCobertura: false,
      aptoSinCobertura: false,
      devueltoAlCobrador: false,
      altaProvisoria: false,
      bajaPorRechazo: false,
      facturado: false,
      tiposSolicitudes: [],
      tipoSolicitudSelected: null,
      estadoNom: null,
      newId: null,
      loadingBtn: false
    };
  },
  created() {
    this.setSelects();
    if (this.estSolId != null) {
      this.setEstadoSolicitud();
    } else {
      this.newEstadoSolicitud();
    }
  },
  methods: {
    ...mapActions({
      getTipoDeSolicitud: "configAfiliaciones/getTipoDeSolicitud",
      getEstadoSolicitudById: "configAfiliaciones/getEstadoSolicitudById",
      postEstadosDeSolicitudes: "configAfiliaciones/postEstadosDeSolicitudes",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const tiposSolicitudes = await this.getTipoDeSolicitud();
      this.tiposSolicitudes = tiposSolicitudes;
    },
    async setEstadoSolicitud() {
      const response = await this.getEstadoSolicitudById(this.estSolId);
      this.estadoNom = response.estSolNom;
      this.cargaInicial = response.estSolInicial;
      this.altaConCobertura = response.estSolAlta;
      this.altaProvisoria = response.estSolAltaProvisoria;
      if (this.altaConCobertura) this.aptoSinCobertura = false;
      else this.aptoSinCobertura = response.estSolApto;
      this.facturado = response.estSolFacturado;
      this.bajaPorRechazo = response.estSolBajaRechazo;
      this.devueltoAlCobrador = response.estSolDevueltoCobrador;
      this.tipoSolicitudSelected = this.tipoSolicitudId;
    },
    newEstadoSolicitud() {
      this.formEditTitle = "Nuevo estado de solicitud";
      this.tipoSolicitudSelected = this.tipoSolicitudId;
    },
    async saveEdit() {
      this.loadingBtn = true;
      const data = {
        estSolId: this.estSolId != null ? this.estSolId : null,
        solicitudTipoId: this.tipoSolicitudSelected,
        estSolInicial: this.cargaInicial,
        estSolAlta: this.aptoSinCobertura ? false : this.altaConCobertura,
        estSolApto: this.altaConCobertura ? true : this.aptoSinCobertura,
        estSolDevueltoCobrador: this.devueltoAlCobrador,
        estSolAltaProvisoria: this.altaProvisoria,
        estSolBajaRechazo: this.bajaPorRechazo,
        estSolFacturado: this.facturado,
        estSolNom: this.estadoNom
      };
      const res = await this.postEstadosDeSolicitudes(data);
      if (res.status === 200) {
        this.newId = res.data.data;
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
      this.loadingBtn = false;
    },
    closeModal() {
      this.$emit("closeAndReload", this.newId);
    }
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
</style>
