<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="formIsValid"
          ref="form-heredar-config"
          form="form-heredar-config"
          id="form-heredar-config"
          @submit.prevent="saveHeredarConfig"
        >
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="tiposSolicitudes"
                v-model="tipoSolicitudSelected"
                :search="searchTiposSolicitud"
                item-key="solicitudTipoId"
                show-select
                single-select
                dense
                class="elevation-1"
              >
                <template v-slot:[`item.cargaSinEntregaPrevia`]="{ item }">
                  <v-icon
                    v-if="item.cargaSinEntregaPrevia"
                    small
                    color="primary"
                  >
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:[`item.generaNumeracionAutomatica`]="{ item }">
                  <v-icon
                    v-if="item.generaNumeracionAutomatica"
                    small
                    color="primary"
                  >
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:[`item.modificaConvenio`]="{ item }">
                  <v-icon v-if="item.modificaConvenio" small color="primary">
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:[`item.esTraspaso`]="{ item }">
                  <v-icon v-if="item.esTraspaso" small color="primary">
                    {{ checkIcon }}
                  </v-icon>
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                          v-model="searchTiposSolicitud"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template
                  v-slot:[`item.data-table-select`]="{
                    isSelected,
                    select
                  }"
                >
                  <v-simple-checkbox
                    :value="isSelected"
                    color="primary"
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!formIsValid || loadingBtn"
          :loading="loadingBtn"
          form="form-heredar-config"
          color="primary"
        >
          Heredar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";

export default {
  name: "HeredarConfigTipoSolicitud",
  props: {
    tipoSolicitudId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      formIsValid: false,
      formEditTitle: "Heredar configuración de un tipo de solicitud",
      rules: rules,
      searchIcon: enums.icons.SEARCH,
      checkIcon: enums.icons.CHECK_OUTLINE,
      searchTiposSolicitud: "",
      headers: [
        {
          text: "Tipo de solicitud",
          align: "start",
          value: "solicitudTipoNom",
          sortable: false
        },
        {
          text: "Tipo titular",
          align: "start",
          value: "tipoTitularNom",
          sortable: false
        },
        {
          text: "Modifica convenio",
          align: "center",
          value: "modificaConvenio",
          sortable: false
        },
        {
          text: "Datos adicionales",
          align: "center",
          value: "esTraspaso",
          sortable: false
        },
        {
          text: "Sin entrega previa",
          align: "center",
          value: "cargaSinEntregaPrevia",
          sortable: false
        },
        {
          text: "Numeración automática",
          align: "center",
          value: "generaNumeracionAutomatica",
          sortable: false
        }
      ],
      tiposSolicitudes: [],
      tipoSolicitudSelected: [],
      openModalEditEstado: false,
      loadingBtn: false
    };
  },
  created() {
    this.setTiposSolicitudes();
  },
  methods: {
    ...mapActions({
      getTiposSolicitudes: "configAfiliaciones/getTiposSolicitudes",
      heredarConfiguracionTipoSolicitud:
        "configAfiliaciones/heredarConfiguracionTipoSolicitud",
      setAlert: "user/setAlert"
    }),
    async setTiposSolicitudes() {
      const tiposSolicitudes = await this.getTiposSolicitudes();
      this.tiposSolicitudes = tiposSolicitudes.filter(
        x => x.solicitudTipoId != this.tipoSolicitudId
      );
    },
    async saveHeredarConfig() {
      this.loadingBtn = true;
      const data = {
        tipoSolicitudToCopy: this.tipoSolicitudSelected[0].solicitudTipoId,
        TipoSolicitudInherits: this.tipoSolicitudId
      };

      const res = await this.heredarConfiguracionTipoSolicitud(data);
      if (res.status === 200) {
        this.formIsValid = false;
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
      this.loadingBtn = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    toggleModalEditEstado() {
      this.openModalEditEstado = !this.openModalEditEstado;
    }
  }
};
</script>

<style scoped></style>
