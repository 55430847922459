<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isValidForm"
          ref="form-destinos-estados"
          form="form-destinos-estados"
          id="form-destinos-estados"
          @submit.prevent="saveDestinosEstadoSolicitud"
        >
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="estados"
                v-model="estadosSelected"
                item-key="estSolId"
                show-select
                :search="searchDestinosEstados"
                dense
                class="elevation-1"
                @toggle-select-all="selectAllGruposUsuariosToggle"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                          v-model="searchDestinosEstados"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" align="end" v-if="canCreate">
                        <v-btn
                          color="primary"
                          class="to-right"
                          small
                          @click="toggleModalEditEstado()"
                        >
                          Nuevo
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template
                  v-slot:[`item.data-table-select`]="{
                    isSelected,
                    select
                  }"
                >
                  <v-simple-checkbox
                    :disabled="!canConfigDestinos"
                    :value="isSelected"
                    color="primary"
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          v-if="canConfigDestinos"
          :loading="loadingBtn"
          type="submit"
          :disabled="!isValidForm || loadingBtn"
          form="form-destinos-estados"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-if="openModalEditEstado"
        v-model="openModalEditEstado"
        max-width="50%"
        @keydown.esc="closeAndReloadModalEditEstado()"
        persistent
      >
        <EditEstadoSolicitud
          :tipoSolicitudId="tipoSolicitudId"
          @closeAndReload="closeAndReloadModalEditEstado"
        />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import EditEstadoSolicitud from "@/components/modules/afiliaciones/afiliados/configuracion/EditEstadoSolicitud.vue";

export default {
  name: "DestinosEstadosSolicitud",
  components: { EditEstadoSolicitud },
  props: {
    canConfigDestinos: {
      type: Boolean,
      required: true
    },
    canCreate: {
      type: Boolean,
      required: true
    },
    estSolIdActual: {
      type: Number,
      required: true
    },
    tipoSolicitudId: {
      type: Number,
      required: true
    },
    estSolNom: {
      type: String,
      required: true
    },
    estadosDestino: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isValidForm: false,
      formEditTitle: `Destinos del estado: ${this.estSolNom}`,
      rules: rules,
      searchIcon: enums.icons.SEARCH,
      searchDestinosEstados: "",
      headers: [
        {
          text: "Nombre de estado de solicitud destino",
          align: "start",
          value: "estSolNom",
          sortable: false
        }
      ],
      estados: [],
      estadosSelected: [],
      openModalEditEstado: false,
      loadingBtn: false
    };
  },
  created() {
    this.setDetinosEstado();
  },
  methods: {
    ...mapActions({
      getDestinosEstadoById: "configAfiliaciones/getDestinosEstadoById",
      saveDestinosEstado: "configAfiliaciones/saveDestinosEstado",
      getEstadosSolicitudByTipoSolicitud:
        "configAfiliaciones/getEstadosSolicitudByTipoSolicitud",
      setAlert: "user/setAlert"
    }),
    async setDetinosEstado() {
      this.estados = this.estadosDestino;
      const estadosDestino = await this.getDestinosEstadoById(
        this.estSolIdActual
      );
      this.estados.forEach(x => {
        estadosDestino.forEach(y => {
          if (y.estSolIdDestino === x.estSolId) this.estadosSelected.push(x);
        });
      });
    },
    selectAllGruposUsuariosToggle() {
      if (!this.canConfigGroups) {
        this.estadosSelected = [];
        this.setDetinosEstado();
        this.setAlert({
          type: "warning",
          message: "No se puede seleccionar ningún estado."
        });
        return;
      }
      if (this.estadosSelected.length != this.estados.length) {
        this.estadosSelected = [];
        this.estados.forEach(item => {
          this.estadosSelected.push(item);
        });
        this.setAlert({
          type: "info",
          message: "Todos los estados han sido seleccionados."
        });
      } else {
        this.estadosSelected = [];
        this.setAlert({
          type: "info",
          message: "Todos los estados han sido deseleccionados."
        });
      }
    },
    async saveDestinosEstadoSolicitud() {
      this.loadingBtn = true;
      let destinos = [];
      if (this.estadosSelected.length > 0)
        this.estadosSelected.forEach(x => {
          destinos.push({
            estSolIdActual: this.estSolIdActual,
            estSolIdDestino: x.estSolId
          });
        });
      else
        destinos.push({
          estSolIdActual: this.estSolIdActual,
          estSolIdDestino: null
        });
      const data = {
        destinosEstadosData: destinos
      };

      const res = await this.saveDestinosEstado(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
      this.loadingBtn = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    closeAndReloadModalEditEstado(id) {
      this.openModalEditEstado = false;
      this.newId = id;
      this.setEstadosByTipoSolicitud();
    },
    toggleModalEditEstado() {
      this.openModalEditEstado = !this.openModalEditEstado;
    },
    async setEstadosByTipoSolicitud() {
      const response = await this.getEstadosSolicitudByTipoSolicitud(
        this.tipoSolicitudId
      );
      this.estados = response.filter(x => x.estSolId != this.estSolIdActual);
      const findNewDestino = this.estados.find(x => x.estSolId === this.newId);
      this.estadosSelected.push(findNewDestino);
    }
  }
};
</script>

<style scoped>
.preview-row {
  height: 150px;
  overflow-y: scroll;
  border: 1px solid #e6e6e6;
  margin: 0 auto;
  width: 75%;
}
.preview-container {
  margin-bottom: 2rem 0;
  padding: 0;
  width: 100%;
}

::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
