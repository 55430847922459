var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"pt-0"},[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form-heredar-config",attrs:{"form":"form-heredar-config","id":"form-heredar-config"},on:{"submit":function($event){$event.preventDefault();return _vm.saveHeredarConfig($event)}},model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tiposSolicitudes,"search":_vm.searchTiposSolicitud,"item-key":"solicitudTipoId","show-select":"","single-select":"","dense":""},scopedSlots:_vm._u([{key:"item.cargaSinEntregaPrevia",fn:function(ref){
var item = ref.item;
return [(item.cargaSinEntregaPrevia)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.generaNumeracionAutomatica",fn:function(ref){
var item = ref.item;
return [(item.generaNumeracionAutomatica)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.modificaConvenio",fn:function(ref){
var item = ref.item;
return [(item.modificaConvenio)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.esTraspaso",fn:function(ref){
var item = ref.item;
return [(item.esTraspaso)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchTiposSolicitud),callback:function ($$v) {_vm.searchTiposSolicitud=$$v},expression:"searchTiposSolicitud"}})],1)],1)],1)]},proxy:true},{key:"item.data-table-select",fn:function(ref){
                var isSelected = ref.isSelected;
                var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"color":"primary","ripple":false},on:{"input":function($event){return select($event)}}})]}}],null,true),model:{value:(_vm.tipoSolicitudSelected),callback:function ($$v) {_vm.tipoSolicitudSelected=$$v},expression:"tipoSolicitudSelected"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.formIsValid || _vm.loadingBtn,"loading":_vm.loadingBtn,"form":"form-heredar-config","color":"primary"}},[_vm._v(" Heredar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }