<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="tiposSolicitudes"
          :search="search"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.cargaSinEntregaPrevia`]="{ item }">
            <v-icon v-if="item.cargaSinEntregaPrevia" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.generaNumeracionAutomatica`]="{ item }">
            <v-icon
              v-if="item.generaNumeracionAutomatica"
              small
              color="primary"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.modificaConvenio`]="{ item }">
            <v-icon v-if="item.modificaConvenio" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.esTraspaso`]="{ item }">
            <v-icon v-if="item.esTraspaso" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.unicoPlanXGrupoFamiliar`]="{ item }">
            <v-icon v-if="item.unicoPlanXGrupoFamiliar" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" align="end" v-if="canCreate">
                  <v-btn
                    color="primary"
                    class="to-right"
                    @click="openModalEditSolicitud()"
                  >
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canConfig">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalConfigSolicitud(item)"
                >
                  {{ settingsIcon }}
                </v-icon>
              </template>
              <span>Configurar tipo de solicitud</span>
            </v-tooltip>
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalEditSolicitud(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar tipo de solicitud</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteSolicitud(item.solicitudTipoId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar tipo de solicitud</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="configSolicitud"
      v-model="configSolicitud"
      max-width="90%"
      @keydown.esc="closeAndReload()"
      persistent
    >
      <ConfigTipoSolicitud
        :tipoSolicitudId="tipoSolicitudId"
        :tipoSolicitudNom="tipoSolicitudNom"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <v-dialog
      v-if="modalEditSolicitud"
      v-model="modalEditSolicitud"
      max-width="50%"
      @keydown.esc="closeAndReload()"
      persistent
    >
      <EditTiposSolicitud
        :tipoSolicitud="tipoSolicitud"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import ConfigTipoSolicitud from "@/components/modules/afiliaciones/afiliados/configuracion/ConfigTipoSolicitud.vue";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import EditTiposSolicitud from "@/components/modules/afiliaciones/afiliados/configuracion/EditTiposSolicitud.vue";

export default {
  name: "TiposSolicitud",
  components: {
    PageHeader,
    DeleteDialog,
    ConfigTipoSolicitud,
    EditTiposSolicitud,
    GoBackBtn,
    Ayuda
  },

  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    settingsIcon: enums.icons.SETTINGS,
    checkIcon: enums.icons.CHECK_OUTLINE,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.TIPOS_DE_SOLICITUD,
    showDeleteModal: false,
    idToDelete: null,
    configSolicitud: false,
    searchIcon: enums.icons.SEARCH,
    search: "",
    title: enums.titles.TIPOS_DE_SOLICITUD,
    routeToGo: "SolicitudesDeAfiliacion",
    titleDelete: "¿Desea eliminar el tipo de solicitud?",
    tiposSolicitudes: [],
    tipoSolicitudId: null,
    tipoSolicitudNom: null,
    rules: rules,
    expanded: [],
    headers: [
      {
        text: "Tipo de solicitud",
        align: "start",
        value: "solicitudTipoNom",
        sortable: false
      },
      {
        text: "Tipo titular",
        align: "start",
        value: "tipoTitularNom",
        sortable: false
      },
      {
        text: "Modifica convenio",
        align: "center",
        value: "modificaConvenio",
        sortable: false
      },
      {
        text: "Datos adicionales",
        align: "center",
        value: "esTraspaso",
        sortable: false
      },
      {
        text: "Sin entrega previa",
        align: "center",
        value: "cargaSinEntregaPrevia",
        sortable: false
      },
      {
        text: "Numeración automática",
        align: "center",
        value: "generaNumeracionAutomatica",
        sortable: false
      },
      {
        text: "Plan único para el grupo familiar",
        align: "center",
        value: "unicoPlanXGrupoFamiliar",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canConfig: false,
    modalEditSolicitud: false,
    tipoSolicitud: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });

    this.loadTiposSolicitudes();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getTiposSolicitudes: "configAfiliaciones/getTiposSolicitudes",
      deleteTiposSolicitudes: "configAfiliaciones/deleteTiposSolicitudes",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_TIPO_DE_SOLICITUD:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_TIPO_DE_SOLICITUD:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_TIPO_DE_SOLICITUD:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONFIGURACION_TIPO_DE_SOLICITUD:
            this.canConfig = true;
            break;
          default:
            break;
        }
      });
    },
    async loadTiposSolicitudes() {
      const tiposSolicitudes = await this.getTiposSolicitudes();
      this.tiposSolicitudes = tiposSolicitudes;
    },
    deleteSolicitud(id) {
      this.idToDelete = id;
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      const response = await this.deleteTiposSolicitudes(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadTiposSolicitudes();
      }
    },
    openModalEditSolicitud(item) {
      this.tipoSolicitud = item;
      this.modalEditSolicitud = !this.modalEditSolicitud;
    },
    openModalConfigSolicitud(item) {
      this.configSolicitud = true;
      this.tipoSolicitudId = item.solicitudTipoId;
      this.tipoSolicitudNom = item.solicitudTipoNom;
    },
    closeAndReload() {
      this.configSolicitud = false;
      this.modalEditSolicitud = false;
      this.loadTiposSolicitudes();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.preview-row {
  height: 150px;
  overflow-y: scroll;
  border: 1px solid #e6e6e6;
  margin: 0 auto;
  width: 75%;
}
.preview-container {
  margin-bottom: 2rem 0;
  padding: 0;
  width: 100%;
}

::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
